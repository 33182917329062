.footer {
  background: #f8f9fa;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #dee2e6;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer p {
  color: #6c757d;
  margin: 0;
}

.footer-links {
  display: flex;
  gap: 20px;
}

.footer-links a {
  color: #6c757d;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #4CAF50;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .footer {
    background: #1a1a1a;
    border-top: 1px solid #2d2d2d;
  }

  .footer p,
  .footer-links a {
    color: #adb5bd;
  }

  .footer-links a:hover {
    color: #8BC34A;
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .footer-links {
    justify-content: center;
  }
} 