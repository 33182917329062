body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #333333;
}

* {
  box-sizing: border-box;
}
