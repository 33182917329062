.flowchart-wrapper {
  min-height: calc(100vh - 160px);
  padding: 100px 20px 60px 20px;
  margin-top: 60px;
  background: linear-gradient(to bottom, #f8f9fa, #e9ecef);
}

.flowchart-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  text-align: center;
  margin-bottom: 20px;
}

.card h2 {
  color: #2c3e50;
  margin-bottom: 30px;
  font-size: 1.8rem;
  font-weight: 600;
}

.button-grid {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
  margin: 0 auto;
}

.category-button {
  background: white;
  border: 2px solid #4CAF50;
  color: #4CAF50;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.category-button:hover {
  background: #4CAF50;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.2);
}

.back-button {
  background: transparent;
  border: 2px solid #6c757d;
  color: #6c757d;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 30px;
}

.back-button:hover {
  background: #6c757d;
  color: white;
  transform: translateY(-2px);
}

/* Solution Card Specific Styles */
.solution-card {
  background: white;
  text-align: left;
}

.solution-header {
  margin: -40px -40px 30px -40px;
  padding: 30px 40px;
  background: linear-gradient(135deg, #4CAF50, #45a049);
  border-radius: 20px 20px 0 0;
  color: white;
}

.solution-header h2 {
  color: white;
  margin: 0 0 10px 0;
}

.severity-badge {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  font-size: 0.9rem;
}

.solution-content {
  padding: 20px 0;
}

.solution-content p {
  white-space: pre-line;
  line-height: 1.8;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.solution-footer {
  margin-top: 30px;
  text-align: center;
}

.primary-button {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 15px 40px;
  font-size: 1.1rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .flowchart-wrapper {
    padding: 80px 15px 40px 15px;
  }

  .card {
    padding: 30px 20px;
  }

  .card h2 {
    font-size: 1.5rem;
  }

  .category-button {
    padding: 12px 20px;
    font-size: 1rem;
  }

  .solution-header {
    margin: -30px -20px 20px -20px;
    padding: 25px 20px;
  }
}

.solution-text {
  white-space: pre-line;
  font-family: inherit;
  line-height: 1.8;
  color: #4a4a4a;
  margin: 0;
  text-align: left;
}

/* Add these new styles */
.solution-section {
  margin-bottom: 40px;
}

.solution-section h3 {
  color: #2c3e50;
  font-size: 1.4rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.content-box {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.action-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 15px;
  color: #4a4a4a;
  font-size: 1.05rem;
  line-height: 1.6;
}

.bullet {
  color: #4CAF50;
  font-weight: bold;
}

.seasonal-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.season-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.season-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.season-card h4 {
  color: #4CAF50;
  margin-bottom: 15px;
  font-size: 1.2rem;
  border-bottom: 2px solid #e9ecef;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.season-card h4::before {
  content: '';
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.season-card:nth-child(1) h4::before {
  content: '🌱';
}

.season-card:nth-child(2) h4::before {
  content: '☀️';
}

.season-card:nth-child(3) h4::before {
  content: '🍂';
}

.season-card:nth-child(4) h4::before {
  content: '❄️';
}

.season-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.season-content .action-item {
  font-size: 1rem;
  margin-bottom: 0;
}

/* Responsive design */
@media (max-width: 768px) {
  .seasonal-grid {
    grid-template-columns: 1fr;
  }

  .season-card {
    padding: 20px;
  }
} 