.welcome-container {
  min-height: 100vh;
  background: #f8f9fa;
  color: #2c3e50;
}

/* Hero Section */
.hero-section {
  position: relative;
  height: auto;
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #43A047, #66BB6A, #A5D6A7);
  color: white;
  text-align: center;
  padding: 3rem 2rem;
  margin-top: 60px;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.1) 1px, transparent 0);
  background-size: 20px 20px;
  opacity: 0.5;
  pointer-events: none;
}

.hero-content {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin: 0.5rem 0;
  font-weight: 700;
  line-height: 1.2;
}

.hero-subtitle {
  font-size: 1.1rem;
  opacity: 0.9;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.hero-cta-button {
  background: white;
  color: #43A047;
  border: none;
  padding: 15px 40px;
  font-size: 1.1rem;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.hero-cta-button:hover {
  background: #f8f9fa;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(67, 160, 71, 0.3);
}

/* Features Section */
.features-section {
  padding: 4rem 2rem;
  background: #ffffff;
  color: #2c3e50;
}

.features-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

.feature-card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  text-align: center;
  color: #2c3e50;
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 20px;
  animation: float 3s ease-in-out infinite;
}

.feature-card h3 {
  color: #2c3e50;
  margin-bottom: 15px;
  font-size: 1.3rem;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
}

/* Testimonials Section */
.testimonials-section {
  padding: 4rem 2rem;
  background: #ffffff;
  color: #2c3e50;
  text-align: center;
}

.testimonials-section h2 {
  margin-bottom: 3rem;
  color: #2c3e50;
  font-size: 2rem;
}

.testimonials-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.testimonial-card {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  color: #2c3e50;
}

.testimonial-card p {
  font-style: italic;
  color: #555;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.testimonial-card cite {
  color: #888;
  display: block;
  margin-top: 1rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .hero-section {
    height: auto;
    min-height: 350px;
    padding-top: 100px;
    margin-top: 100px;
    padding: 2rem 1rem;
  }

  .hero-content {
    width: 100%;
    padding: 0 15px;
  }

  .hero-content h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .hero-cta-button {
    padding: 12px 30px;
    font-size: 1rem;
  }

  .features-grid {
    gap: 20px;
  }

  .feature-card {
    padding: 25px;
  }
}

/* Additional mobile optimization */
@media (max-width: 480px) {
  .hero-section {
    padding-top: 80px;
    margin-top: 120px;
  }

  .hero-content h1 {
    font-size: 1.8rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }
}

/* Dark mode support for header overlap */
.dark-mode .hero-section {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Add after existing hero section styles */

.trust-indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.trust-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  backdrop-filter: blur(5px);
  height: 36px;
  white-space: nowrap;
}

.trust-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 20px;
  height: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .trust-indicators {
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .trust-item {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
    height: 32px;
  }
}

@media (max-width: 480px) {
  .trust-indicators {
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
  }
}

/* Statistics Section */
.statistics-section {
  padding: 4rem 2rem;
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  position: relative;
  overflow: hidden;
}

.stats-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2.5rem;
  text-align: center;
  position: relative;
  z-index: 2;
}

.stat-item {
  padding: 2rem;
  border-radius: 20px;
  background: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  color: #2c3e50;
}

.stat-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(67, 160, 71, 0.1);
}

.stat-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  animation: float 3s ease-in-out infinite;
}

.stat-item h3 {
  color: #43A047;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  line-height: 1;
}

.stat-item p {
  color: #2c3e50;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.stat-detail {
  color: #666;
  font-size: 0.9rem;
  font-style: italic;
}

/* Background decorative elements */
.stats-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.stats-shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
}

.shape-1 {
  width: 300px;
  height: 300px;
  background: #43A047;
  top: -100px;
  left: -100px;
}

.shape-2 {
  width: 200px;
  height: 200px;
  background: #66BB6A;
  bottom: -50px;
  right: -50px;
}

.shape-3 {
  width: 150px;
  height: 150px;
  background: #81C784;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .statistics-section {
    padding: 3rem 1rem;
  }

  .stat-item {
    padding: 1.5rem;
  }

  .stat-item h3 {
    font-size: 2.5rem;
  }

  .stat-icon {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .stats-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

/* How It Works Section */
.how-it-works-section {
  padding: 5rem 2rem;
  background: #ffffff;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 4rem;
  font-weight: 700;
}

.steps-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
}

.step-item {
  flex: 1;
  max-width: 300px;
  padding: 2rem;
  position: relative;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  color: #2c3e50;
}

.step-item:hover {
  transform: translateY(-5px);
}

.step-number {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #43A047, #66BB6A);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 auto 1.5rem;
  box-shadow: 0 5px 15px rgba(67, 160, 71, 0.3);
}

.step-item h3 {
  color: #2c3e50;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.step-item p {
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
}

.step-connector {
  flex: 0 0 50px;
  height: 3px;
  background: linear-gradient(to right, #43A047, #66BB6A);
  position: relative;
  opacity: 0.3;
}

/* Responsive Design */
@media (max-width: 968px) {
  .steps-container {
    flex-direction: column;
    gap: 1.5rem;
  }

  .step-connector {
    width: 3px;
    height: 30px;
    margin: 0.5rem 0;
  }

  .step-item {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .how-it-works-section {
    padding: 3rem 1rem;
  }

  .section-title {
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }

  .step-item {
    padding: 1.5rem;
  }

  .step-number {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }
}

/* Add these new styles for the hero steps */
.hero-steps-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  padding: 1.2rem;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.hero-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}

.hero-step-number {
  width: 35px;
  height: 35px;
  background: white;
  color: #43A047;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.hero-step p {
  color: white !important;
  font-size: 0.95rem;
  font-weight: 500;
  margin: 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.hero-step-arrow {
  color: white;
  font-size: 1.2rem;
  opacity: 0.9;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-steps-container {
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-step {
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    justify-content: center;
  }

  .hero-step-arrow {
    transform: rotate(90deg);
    margin: -0.5rem 0;
  }
}

@media (max-width: 480px) {
  .hero-steps-container {
    padding: 0.8rem;
  }

  .hero-step-number {
    width: 30px;
    height: 30px;
    font-size: 0.9rem;
  }

  .hero-step p {
    font-size: 0.85rem;
  }
}

/* Bottom CTA Section */
.bottom-cta-section {
  padding: 5rem 2rem;
  background: linear-gradient(135deg, #f8f9fa, #ffffff);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.bottom-cta-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.bottom-cta-content h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 700;
}

.bottom-cta-content p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.primary-cta-button {
  background: linear-gradient(135deg, #43A047, #66BB6A);
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(67, 160, 71, 0.3);
  transition: all 0.3s ease;
}

.primary-cta-button:hover {
  box-shadow: 0 6px 20px rgba(67, 160, 71, 0.4);
}

.bottom-cta-trust {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: #666;
  font-size: 0.9rem;
}

.bottom-cta-trust span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .bottom-cta-section {
    padding: 4rem 1.5rem;
  }

  .bottom-cta-content h2 {
    font-size: 2rem;
  }

  .bottom-cta-content p {
    font-size: 1.1rem;
  }

  .cta-buttons {
    margin-bottom: 1.5rem;
  }

  .bottom-cta-trust {
    flex-direction: column;
    gap: 0.5rem;
  }

  .bottom-cta-trust span:nth-child(even) {
    display: none; /* Hide dots on mobile */
  }
}

@media (max-width: 480px) {
  .bottom-cta-section {
    padding: 3rem 1rem;
  }

  .bottom-cta-content h2 {
    font-size: 1.8rem;
  }

  .primary-cta-button {
    width: 100%;
    text-align: center;
  }
}

/* Ensure all text colors are explicitly set */
h1, h2, h3, h4, h5, h6 {
  color: #2c3e50;
}

p {
  color: #666;
}

/* Hero section text should remain white */
.hero-section h1,
.hero-section p,
.hero-section .hero-step p {
  color: white;
}

/* Simple Steps Styling */
.simple-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.step {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.step-dot {
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  opacity: 0.9;
}

.step-text {
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  opacity: 0.9;
}

.step-line {
  width: 30px;
  height: 1px;
  background: rgba(255, 255, 255, 0.4);
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .simple-steps {
    gap: 0.8rem;
  }
  
  .step-text {
    font-size: 0.8rem;
  }
  
  .step-line {
    width: 20px;
  }
}